import TYPES from '@/types';
import { clabeFormat, requiredRule } from '@/vue-app/utils/form-rules';
import { convertToLargerPossibleUnit } from '@/vue-app/utils/files';
import { clabe } from 'clabe-validator';
import i18n from '@/vue-app/plugins/i18n';
import { v4 as uuid } from 'uuid';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import {
  SearchFinancialInstitutionsQuery,
} from '@/modules/my-investment/catalogs/kuspit/financial-institution/application/queries';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import SearchAllianzFinancialInstitutionsQuery
  from '@/modules/my-investment/catalogs/allianz/financial-institution/application/queries/search-financial-institutions-query';
import {
  UploadFileAccountStatusCommand,
} from '@/modules/my-investment/upload-file/application/commands';
import {
  SearchCustomerDocumentsQuery,
} from '@/modules/my-investment/customer-documents/application/queries';
import { GetFileQuery } from '@/modules/my-investment/file/application/queries';

// Domain
import {
  FinancialInstitutionEntity,
} from '@/modules/my-investment/catalogs/kuspit/financial-institution/domain/entities/financial-institution-entity';
import {
  FinancialInstitutionEntity as AllianzFinancialInstitutionEntity,
} from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/entities/financial-institution-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import { FileEntity } from '@/modules/my-investment/file/domain/entities/file-entity';
import {
  CustomerDocumentEntity,
} from '@/modules/my-investment/customer-documents/domain/entities/customer-document-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsFinancialInformationViewModel {
  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_FINANCIAL_INSTITUTIONS_QUERY)
  private readonly searchFinancialInstitutionsQuery!: SearchFinancialInstitutionsQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_FINANCIAL_INSTITUTION_QUERY)
  private readonly searchAllianzFinancialInstitutionsQuery!:
    SearchAllianzFinancialInstitutionsQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.UTIL_FUNCTIONS)
  readonly functions!: Functions;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.MY_INVESTMENT_UPLOAD_FILE_ACCOUNT_STATUS_COMMAND)
  private readonly upload_file_account_status_command!: UploadFileAccountStatusCommand;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_CUSTOMER_DOCUMENTS_QUERY)
  private readonly search_customer_documents_query!: SearchCustomerDocumentsQuery;

  @Inject(TYPES.MY_INVESTMENT_GET_FILE_QUERY)
  private readonly get_file_query!: GetFileQuery;

  readonly limit_file_size = 4 * 1024 * 1024;

  readonly allowed_extensions = ['image/jpeg', 'image/jpg', 'image/png'];

  readonly i18n_namespace = 'components.contract-savings.financial-information';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  small_screen: null | boolean = null;

  invalid_file = false;

  bank = {
    clabe: '',
    code: '',
  };

  rules = {
    clabe: [requiredRule, clabeFormat],
    bank_institution: [requiredRule],
  };

  account_status: File | null = null;

  account_status_accept = 'image/png, image/jpeg, image/jpg';

  form_validity = false;

  investment_provider_name = '';

  exists_step = false;

  step_name = '';

  investment_provider_id = '';

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  private payload = {
    clabe: '',
    bank: {
      id: '',
      description: '',
    },
    file: {
      id: uuid(),
      name: '',
      mime_type: '',
      file_data: '',
    },
  };

  private payload_allianz = {
    account_number: '',
    status_account: {
      file_name: '',
      file_weight: '',
      was_uploaded: false,
    },
    financial_institution: { },
  }

  uploading_file = false;

  bank_institution = '';

  service = '';

  allianz_financial_institutions: Array<AllianzFinancialInstitutionEntity> = [];

  kuspit_financial_institutions: Array<FinancialInstitutionEntity> = [];

  customer_documents: CustomerDocumentEntity[] = [];

  loadInvestmentsProviders = async () => {
    try {
      const investment_providers = await this.searchInvestmentProvidersQuery.execute();
      this.setInvestmentProviderId(investment_providers);
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_investment_providers`));
    }
  }

  loadSteps = async () => {
    try {
      const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
      await this.verifyStep(steps);
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_steps`));
    }
  }

  loadCustomerDocuments = async () => {
    try {
      this.customer_documents = await this.search_customer_documents_query.execute();
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_customer_documents`));
    }
  }

  getFile = async (customer_document_id: string): Promise<FileEntity | null> => {
    try {
      const file = await this.get_file_query.execute(customer_document_id);
      return file;
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_file`));
      return null;
    }
  }

  get user_name() {
    const person = this.get_person_query.execute();
    return person.name;
  }

  get styledFileName(): string {
    if (this.account_status) {
      return `${this.account_status.name.slice(0, 30)}${this.account_status.name.length > 35 ? '...' : ''}`;
    }

    return i18n.t(`${this.i18n_namespace}.account_status.placeholder`)
      .toString();
  }

  get canContinue(): boolean {
    return this.form_validity && !!this.account_status && !!this.bank_institution
      && !this.invalid_file;
  }

  get styledFileSize(): string {
    if (this.account_status) {
      return convertToLargerPossibleUnit(this.account_status.size);
    }
    return '0 kb';
  }

  changeClabe = () => {
    if (this.bank.clabe.length >= 3) {
      this.bank_institution = '';
      if (this.service === 'allianz') {
        this.filterInstitutionsAllianz(this.allianz_financial_institutions);
      } else {
        this.filterInstitutions(this.kuspit_financial_institutions);
      }
    }
  }

  format_bank_institution = (bank_institution: string) => {
    let formatted_bank_institution = bank_institution;
    const internal_key_position = bank_institution.indexOf('SW');
    const dom_position = bank_institution.indexOf('(DOM');
    if (internal_key_position >= 0) {
      formatted_bank_institution = bank_institution.substring(0, internal_key_position);
    }
    if (dom_position >= 0) {
      formatted_bank_institution = bank_institution.substring(0, dom_position);
    }
    return formatted_bank_institution;
  }

  convertFileToBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  );

  convertBase64ToFile = async (base64: string, mime_type: string, file_name: string) => (
    this.functions.convert_base_64_to_file(base64, mime_type, file_name)
  );

  filterInstitutions(financial_institutions: Array<FinancialInstitutionEntity>) {
    this.is_loading = true;
    if (this.bank.clabe) {
      const clabe_without_space = this.bank.clabe.replace(/\s/g, '');
      const validation = clabe.validate(clabe_without_space);
      const bank_code = validation.code.bank;
      const is_valid = validation.formatOk;
      if (is_valid) {
        const search_bank = financial_institutions.find(
          (bank) => bank.id.slice(bank.id.length - 3) === bank_code,
        );
        if (!search_bank) {
          this.messageNotifier.showErrorNotification(
            i18n.t(`${this.i18n_namespace}.errors.load_financial_institution`).toString(),
          );
        }
        if (search_bank) {
          this.payload.bank = {
            id: search_bank.id,
            description: search_bank.descripcion,
          };
          this.bank_institution = search_bank.descripcion;
        }
      }
    }
    this.is_loading = false;
  }

  filterInstitutionsAllianz(financial_institutions: Array<AllianzFinancialInstitutionEntity>) {
    this.is_loading = true;
    if (this.bank.clabe) {
      const clabe_without_space = this.bank.clabe.replace(/\s/g, '');
      const validation = clabe.validate(clabe_without_space);
      const bank_code = validation.code.bank;
      const is_valid = validation.formatOk;
      if (is_valid) {
        const search_bank = financial_institutions.find(
          (bank) => bank.cveBancaria === bank_code,
        );
        if (!search_bank) {
          this.messageNotifier.showErrorNotification(
            i18n.t(`${this.i18n_namespace}.errors.load_financial_institution`).toString(),
          );
        }

        if (search_bank) {
          this.payload_allianz.financial_institution = {
            codEntFinan: search_bank.codEntFinan,
            cveBancaria: search_bank.cveBancaria,
            mtoMaxEnvio: search_bank.mtoMaxEnvio,
            mtoMinEnvio: search_bank.mtoMinEnvio,
            descEntFinan: search_bank.descEntFinan,
          };
          this.bank_institution = this.format_bank_institution(search_bank.descEntFinan);
        }
      }
    }
    this.is_loading = false;
  }

  async uploadFile(): Promise<boolean> {
    this.uploading_file = true;
    let uploaded = false;
    try {
      const file_dto = {
        name: this.payload.file.id,
        mime_type: this.payload.file.mime_type,
        file_data: this.payload.file.file_data,
      };
      await this.upload_file_account_status_command.execute(file_dto);
      uploaded = true;
    } catch (error) {
      this.messageNotifier.showErrorNotification(this.error_message);
    } finally {
      this.uploading_file = false;
    }
    return uploaded;
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      if (this.investment_provider_name === 'kuspit') {
        await this.setInputsDataFromStep();
      } else {
        await this.setInputsDataFromStepAllianz();
      }
    }
  };

  setInputsDataFromStep = async () => {
    this.bank.clabe = this.on_boarding_step.payload.clabe;
    this.account_status = await this.convertBase64ToFile(
      this.on_boarding_step.payload.file.file_data,
      this.on_boarding_step.payload.file.mime_type,
      this.on_boarding_step.payload.file.name,
    );
  }

  setInputsDataFromStepAllianz = async () => {
    this.bank.clabe = this.on_boarding_step.payload.account_number;
    await this.accountStatusFromStep();
  }

  accountStatusFromStep = async () => {
    await this.loadCustomerDocuments();
    const account_status = this.customer_documents.find(
      (item) => item.document_type.name === 'allianz_account_status',
    );
    if (account_status) {
      const proof_of_address_file = await this.get_file_query.execute(
        account_status.customer_document_id,
      );
      this.account_status = await this.convertBase64ToFile(
        proof_of_address_file.content, proof_of_address_file.mime_type, proof_of_address_file.name,
      );
    }
  }

  setFileData = async (file: File) => {
    if (this.investment_provider_name === 'kuspit') {
      this.payload.file.id = this.exists_step
        ? this.on_boarding_step.payload.file.id : this.payload.file.id;
    }
    this.payload.file.name = file.name;
    this.payload.file.mime_type = file.type;
    const base64 = await this.convertFileToBase64(file);
    if (base64) {
      this.payload.file.file_data = base64.toString();
    }
  };

  handleAccountStatus = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const [file] = target.files;
      if (file.size <= this.limit_file_size && this.allowed_extensions.indexOf(file.type) > -1) {
        this.account_status = file;
        this.invalid_file = false;
      } else {
        this.invalid_file = true;
      }
    }
  }

  loadFinancialInstitutionsByService = async (service: string) => {
    if (service === 'allianz') {
      // eslint-disable-next-line max-len
      this.allianz_financial_institutions = await this.searchAllianzFinancialInstitutionsQuery.execute();
      this.filterInstitutionsAllianz(this.allianz_financial_institutions);
    } else {
      this.kuspit_financial_institutions = await this.searchFinancialInstitutionsQuery.execute();
      this.filterInstitutions(this.kuspit_financial_institutions);
    }
  }

  setStepName = (service: string) => {
    if (service === 'kuspit') {
      this.step_name = 'on_boarding_kuspit_bank_information';
    } else {
      this.step_name = 'account_information';
    }
  }

  saveStepKuspit = async () => {
    let saved = false;
    try {
      this.is_loading = true;
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.payload.clabe = this.bank.clabe.replaceAll(' ', '');
      this.on_boarding_step.payload = this.payload;
      delete this.on_boarding_step.customer_id;
      if (this.exists_step) {
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
      saved = true;
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
    } finally {
      this.is_loading = false;
    }
    return saved;
  }

  saveStepAllianz = async () => {
    let saved = false;
    try {
      this.is_loading = true;
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      const upload_account_status = await this.uploadFile();
      if (upload_account_status) {
        this.payload_allianz.account_number = this.bank.clabe.replaceAll(' ', '');
        this.on_boarding_step.payload = this.payload_allianz;
        this.on_boarding_step.payload.status_account = {
          file_name: this.payload.file.name,
          file_weight: this.styledFileSize,
          file_data: this.payload.file.file_data,
          was_uploaded: true,
        };
        delete this.on_boarding_step.customer_id;
        if (this.exists_step) {
          await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
        } else {
          await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
        }
        saved = true;
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
    } finally {
      this.is_loading = false;
    }
    return saved;
  }

  async saveStep() {
    let saved;
    if (this.investment_provider_name === 'kuspit') {
      saved = await this.saveStepKuspit();
    } else {
      saved = await this.saveStepAllianz();
    }
    return saved;
  }

  initialize = async (screen_variant: string, service_name: string) => {
    this.service = service_name;
    this.small_screen = screen_variant === 'small';
    this.investment_provider_name = service_name;
    this.setStepName(service_name);
    await this.loadInvestmentsProviders();
    await this.loadSteps();
    this.is_loading = false;
  }
}
